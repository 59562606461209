@import './constants/variables.module.scss';

.App {
  flex-direction: column;
  text-align: center;
  background-color: $secondary;
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    height: 100%;
  }
}
