
@font-face {
  font-family: 'Flama';
  font-style: normal;
  font-weight: normal;
  src: local('Flama'), url('./assets/fonts/Flama\ Regular.woff') format('woff');
}

@font-face {
  font-family: 'Flama Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Flama Italic'), url('./assets/fonts/Flama\ Italic.woff') format('woff');
}

@font-face {
  font-family: 'Flama Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Flama Bold Italic'), url('./assets/fonts/Flama\ Bold\ Italic.woff') format('woff');
}

body {
  margin: 0;
  font-family: "Flama", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root{
  width: 100%;
  height: 100%;
}

div{
  display: flex;
  font-family: "Flama";
}

p{
  font-family: "Flama";
}

input{
  font-family: "Flama";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Flama", "Courier New",
    monospace;
}
