@import '../../../constants/variables.module.scss';

.vertical-bar {
  width: 75px;
  justify-content: center;
  align-items: center;

  .vertical-bar-wrapper {
    height: 50%;
    justify-content: center;
    align-items: center;
    border: 1px solid $tertiary;
    flex-direction: column;

    border-width: 3px;
    border-style: solid;
  }

  .left {
    padding: 0% 20px 0 0;
    border-image: linear-gradient(to bottom, $primary, $tertiary) 0 1 0 0;
  }

  .right {
    padding: 0 0 0 20px;
    border-image: linear-gradient(to bottom, $primary, $tertiary) 0 0 0 1;
  }
}
