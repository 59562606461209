.words-matter{
    flex-direction: column;
    width: 250px;
    padding: 5px;
    border: 1px solid #707070; 
    border-radius: 5px;
    
    .input-container{
        height: 15%;
        flex-direction: column;
        justify-content: space-around;
        
        .word{
            height: 25px;
            background-color: #aaaaaa;
            border: 1px solid #707070;
            border-radius: 3px;
            text-align: center;
        }
        
        .score{
            height: 25px;
            background-color: #aaaaaa;
            border: 1px solid #707070;
            border-radius: 2px;
            align-items: center;
            justify-content: center;
        }
    }
    
    .output-container{
        height: 100%;
        border: 1px solid #707070; 
        border-radius: 5px;
        
    }

}