.iconslider-container {
    width: 100%; /* Width of the outside container */
    align-items: center;
    justify-content: center; 
  }
  
  /* The slider itself */
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 10px;   
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  /* Mouse-over effects */
  .slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    border: 0;
    // background: #000;//url('../../assets/logo.svg'); /* Green background */
    background: url('../../../assets/logo.svg'); /* Green background */
    background-size: 25px 25px;
    cursor: pointer; /* Cursor on hover */
  }
  
  .slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    border: 0;
    // background: #555;//url('../../assets/logo.svg'); /* Green background */
    background: url('../../../assets/logo.svg'); /* Green background */
    background-size: 25px 25px;
    cursor: pointer; /* Cursor on hover */
  }