@font-face {
  font-family: 'Flama';
  font-style: normal;
  font-weight: normal;
  src: local("Flama"), url("/static/media/Flama Regular.627325e0.woff") format("woff"); }

@font-face {
  font-family: 'Flama Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Flama Italic"), url("/static/media/Flama Italic.a05324a1.woff") format("woff"); }

@font-face {
  font-family: 'Flama Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Flama Bold Italic"), url("/static/media/Flama Bold Italic.2a43989b.woff") format("woff"); }

body {
  margin: 0;
  font-family: "Flama", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body, #root {
  width: 100%;
  height: 100%; }

div {
  display: flex;
  font-family: "Flama"; }

p {
  font-family: "Flama"; }

input {
  font-family: "Flama"; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Flama", "Courier New", monospace; }

.App_App__3Nh_b {
  flex-direction: column;
  text-align: center;
  background-color: #c0c0c0;
  width: 100%;
  height: 100%; }
  .App_App__3Nh_b .App_content__3QE1g {
    width: 100%;
    height: 100%; }

.navbar_navbar__3TTfj {
  height: 65px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, black), color-stop(15%, #ff8800));
  background-image: -webkit-linear-gradient(black 1%, #ff8800 15%);
  background-image: linear-gradient(black 1%, #ff8800 15%); }
  .navbar_navbar__3TTfj .navbar_logo__14sLp {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%; }
    .navbar_navbar__3TTfj .navbar_logo__14sLp a {
      width: auto; }
    .navbar_navbar__3TTfj .navbar_logo__14sLp img {
      height: 100%; }

.componentviewer_component-viewer-container__1pLYo {
  width: 100%;
  flex-wrap: wrap; }
  .componentviewer_component-viewer-container__1pLYo div {
    width: 150px;
    height: 150px; }

.waterdrop_waterdrop-container__1Bcz0 {
  width: 100px;
  height: 100px;
  border: 1px solid #000000; }

.iconSlider_iconslider-container__3-Dix {
  width: 100%;
  /* Width of the outside container */
  align-items: center;
  justify-content: center; }

/* The slider itself */
.iconSlider_slider__1Dg-5 {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s; }

/* Mouse-over effects */
.iconSlider_slider__1Dg-5:hover {
  opacity: 1;
  /* Fully shown on mouse-over */ }

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.iconSlider_slider__1Dg-5::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  border: 0;
  background: url(/static/media/logo.fffe32db.svg);
  /* Green background */
  background-size: 25px 25px;
  cursor: pointer;
  /* Cursor on hover */ }

.iconSlider_slider__1Dg-5::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  border: 0;
  background: url(/static/media/logo.fffe32db.svg);
  /* Green background */
  background-size: 25px 25px;
  cursor: pointer;
  /* Cursor on hover */ }

.verticalbar_vertical-bar__3rxVm {
  width: 75px;
  justify-content: center;
  align-items: center; }
  .verticalbar_vertical-bar__3rxVm .verticalbar_vertical-bar-wrapper__1Y0C5 {
    height: 50%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    flex-direction: column;
    border-width: 3px;
    border-style: solid; }
  .verticalbar_vertical-bar__3rxVm .verticalbar_left__1x8pM {
    padding: 0% 20px 0 0;
    -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#ff8800), to(black)) 0 1 0 0;
    -webkit-border-image: -webkit-linear-gradient(top, #ff8800, black) 0 1 0 0;
            border-image: -webkit-gradient(linear, left top, left bottom, from(#ff8800), to(black)) 0 1 0 0;
            border-image: linear-gradient(to bottom, #ff8800, black) 0 1 0 0; }
  .verticalbar_vertical-bar__3rxVm .verticalbar_right__2yfvw {
    padding: 0 0 0 20px;
    -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#ff8800), to(black)) 0 0 0 1;
    -webkit-border-image: -webkit-linear-gradient(top, #ff8800, black) 0 0 0 1;
            border-image: -webkit-gradient(linear, left top, left bottom, from(#ff8800), to(black)) 0 0 0 1;
            border-image: linear-gradient(to bottom, #ff8800, black) 0 0 0 1; }

.profilecontainer_profile-container__VBBB7 {
  flex: 1 1;
  justify-content: center;
  align-items: center; }

.profileimage_profile-image__3oB44 {
  border: 2px solid black;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center; }

.profileimage_rotational-character__2TVR8 {
  line-height: 1;
  margin: 0px auto 242px;
  -webkit-animation: profileimage_circle__UfGzG 5s linear infinite;
          animation: profileimage_circle__UfGzG 5s linear infinite;
  -webkit-transform-origin: 50% 130px;
          transform-origin: 50% 130px; }

@-webkit-keyframes profileimage_circle__UfGzG {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes profileimage_circle__UfGzG {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes profileimage_inner-circle__1ENSI {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes profileimage_inner-circle__1ENSI {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.homepage_homepage__3ZNUc {
  width: 100%; }
  .homepage_homepage__3ZNUc .homepage_top-half__zf2tM {
    height: 100%;
    width: 100%;
    background-color: #c0c0c0; }

.svgPlayground_playground-container__3gZAB {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center; }
  .svgPlayground_playground-container__3gZAB .svgPlayground_playground-showcase__1CPoe {
    width: 200px;
    height: 230px;
    background-color: white;
    border-radius: 10px; }

.svgStyle_leaf__ZN_eq {
  border: 1px solid black;
  width: 60px;
  height: 120px;
  border-radius: 60px 60px 60px 100px; }

.WordsMatter_words-matter__2AlXs {
  flex-direction: column;
  width: 250px;
  padding: 5px;
  border: 1px solid #707070;
  border-radius: 5px; }
  .WordsMatter_words-matter__2AlXs .WordsMatter_input-container__2ra2m {
    height: 15%;
    flex-direction: column;
    justify-content: space-around; }
    .WordsMatter_words-matter__2AlXs .WordsMatter_input-container__2ra2m .WordsMatter_word__2g73H {
      height: 25px;
      background-color: #aaaaaa;
      border: 1px solid #707070;
      border-radius: 3px;
      text-align: center; }
    .WordsMatter_words-matter__2AlXs .WordsMatter_input-container__2ra2m .WordsMatter_score__22EEB {
      height: 25px;
      background-color: #aaaaaa;
      border: 1px solid #707070;
      border-radius: 2px;
      align-items: center;
      justify-content: center; }
  .WordsMatter_words-matter__2AlXs .WordsMatter_output-container__3nF52 {
    height: 100%;
    border: 1px solid #707070;
    border-radius: 5px; }

