@import '../../constants/variables.module.scss';

.homepage {
  width: 100%;

  .top-half {
    height: 100%;
    width: 100%;
    background-color: $secondary;
  }
}
